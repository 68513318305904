import styles from "./Logo.module.scss";
import NavigateLink from "./NavigateLink.js";

export default function Logo() {

	// ---- FUNCTIONS ----

	function scrollToAnchor() {
		window.scroll({top: 0, left: 0, behavior: 'smooth'});
	}

    return (
		<div className={styles.container} onClick={scrollToAnchor}>
			<NavigateLink link={"/"} moreClasses={styles.logo}>
				<img className={styles.logo_image} src={"/images/logos/512.png"} alt={"Genesis Tech GmbH - Logo"}/>
				<span className={styles.logo_text}>GENESIS TECH</span>
			</NavigateLink>
		</div>
    );
}