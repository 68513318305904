const baseImagePath = "/images/socials/";

export const socials = [
	{
		link: "mailto:info@genesis-tech.io",
		imagePath: baseImagePath + "email.svg",
		altText: "Genesis Tech GmbH - Email",
		color: "#"
	},
	{
		link: "https://calendly.com/genesistech/45min",
		imagePath: baseImagePath + "calendly.svg",
		altText: "Genesis Tech GmbH - Calendly",
		color: "#"
	},
	{
		link: "https://www.linkedin.com/company/genesis-tech-gmbh",
		imagePath: baseImagePath + "linkedin.svg",
		altText: "Genesis Tech GmbH - LinkedIn",
		color: "#"
	},
	{
		link: "https://twitter.com/genesistechgmbh",
		imagePath: baseImagePath + "twitter.svg",
		altText: "Genesis Tech GmbH - Twitter",
		color: "#1D9BF0"
	}
];

export function getCalendly() {
	return socials[1];
}