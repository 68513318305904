import { useNavigate } from "react-router-dom";
import styles from "./NavigateLink.module.scss";
import { cond } from "../../../lib/html.js";

export default function NavigateLink({link, children, moreClasses, onClick}) {
	const navigate = useNavigate();

	// ---- HANDLE BUTTONS ----

	function handleRouteChange(event) {
		const routes = [
			"/",
			"/privacy-policy",
			"/terms-of-service",
			"/legal-notice"
		];

		let isInternalLink = routes.includes(link);

		if (isInternalLink) {
			event.preventDefault();

			if (onClick !== undefined) {
				onClick();
			}

			navigate(link);
		}
	}

    return (
		<a href={link} className={cond(moreClasses === undefined, styles.link, moreClasses)} target="_blank" rel="noopener noreferrer" onClick={(event) => handleRouteChange(event)}>
			{children}
		</a>
    );
}