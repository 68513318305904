import styles from './Footer.module.scss';
import Logo from '../common/Logo.js';
import NavigateLink from '../common/NavigateLink.js';

import { socials } from "../../../data/socials.js";

export default function Footer() {
	const allSocialData = socials;

    return (
        <footer className={styles.footer}>
			<div className={styles.row_1}>
				<Logo/>

				<div className={styles.social_container}>
					{
						allSocialData.map((socialData, index) => {
							return (
								<div key={index}>
									<NavigateLink link={socialData.link}>
										<div className={styles.social_icon_container}>
											<img className={styles.social_icon} src={socialData.imagePath} alt={socialData.altText}/>
										</div>
									</NavigateLink>
								</div>
							);
						})
					}
				</div>
			</div>

			<hr/>

			<div className={styles.row_2}>
				<div className={styles.copy_right}>
					<span>© Genesis Tech GmbH 2023</span>
					<span>- All rights reserved.</span>
				</div>

				<div className={styles.legal_container}>
					<NavigateLink link={"/privacy-policy"} moreClasses={styles.legal_link}>Privacy Policy</NavigateLink>
					-
					<NavigateLink link={"/terms-of-service"} moreClasses={styles.legal_link}>Terms of Service</NavigateLink>
					-
					<NavigateLink link={"/legal-notice"} moreClasses={styles.legal_link}>Legal Notice</NavigateLink>
				</div>
			</div>
		</footer>
    );
}