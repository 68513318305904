import CookieConsent from "react-cookie-consent";
import styles from "./CookieForm.module.scss";
import NavigateLink from "../../structure/common/NavigateLink.js";

export default function CookieForm() {
    return (
        <CookieConsent
			buttonText={"I Accept"}
			ariaAcceptLabel={"I Accept"}
			declineButtonText={"I Decline"}
			ariaDeclineLabel={"I Decline"}
			cookieName={"genesis-tech-cookie-consent"}
			containerClasses={styles.container}
			buttonClasses={styles.button}
			buttonWrapperClasses={styles.button_wrapper}
			contentClasses={styles.text}
			disableStyles={true}>
			<span>This website uses cookies to enhance the user experience.</span>
			<span>By accepting you also agree to our <NavigateLink link={"/terms-of-service"}>terms of service</NavigateLink>.</span>
		</CookieConsent>
    );
}
