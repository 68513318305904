import styles from './Main.module.scss';
import CookieForm from "../common/CookieForm.js";
import { WindowWidthProvider } from "../../contexts/WindowWidthProvider.js";
import Header from './Header.js';
import Footer from './Footer.js';

export default function Main({children}) {
    return (
        <WindowWidthProvider>
			<Header/>
			
			<main className={styles.main}>
				<div className={styles.container}>
					{children}
				</div>
			</main>

			<CookieForm/>

			<Footer/>
		</WindowWidthProvider>
    );
}