import {lazy, Suspense} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import './App.scss';
import LoadingSpinnerPage from './components/structure/common/LoadingSpinnerPage.js';
import Container from "./components/structure/layout/Container.js";


const Start = lazy(() => import('./components/content/start/Start.js'));

const PrivacyPolicy = lazy(() => import('./components/content/legal/privacyPolicy/PrivacyPolicy.js'));
const TermsOfService = lazy(() => import('./components/content/legal/termsOfService/TermsOfService.js'));
const LegalNotice = lazy(() => import('./components/content/legal/legalNotice/LegalNotice.js'));

const NotFound = lazy(() => import('./components/content/notFound/NotFound.js'));


const gtmId = process.env.REACT_APP_GTM_ID;

if (gtmId !== undefined) {
	TagManager.initialize({ gtmId: gtmId });
}


export default function App() {
    return (
		<BrowserRouter>
			<Suspense fallback={<LoadingSpinnerPage/>}>
				<Routes>
					<Route exact path="/" element={<Container component={<Start/>}/>}/>

					<Route exact path="/privacy-policy" element={<Container component={<PrivacyPolicy/>}/>}/>
					<Route exact path="/terms-of-service" element={<Container component={<TermsOfService/>}/>}/>
					<Route exact path="/legal-notice" element={<Container component={<LegalNotice/>}/>}/>

					<Route path="*" element={<Container component={<NotFound/>}/>}/>
				</Routes>
			</Suspense>
		</BrowserRouter>
    );
}