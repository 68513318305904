import { useContext, useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import styles from './Header.module.scss';
import { WindowWidthContext } from "../../contexts/WindowWidthProvider.js";
import Background from "../common/Background.js";
import Logo from '../common/Logo.js';
import { conc, cond, getCssVariablePixel } from '../../../lib/html.js';

export default function Header() {
	const windowWidth = useContext(WindowWidthContext);

	const [isChecked, setIsChecked] = useState(false);

	const mobileBreakpoint = getCssVariablePixel("--mobile-breakpoint-very-big");

	// ---- HOOKS ----

	useEffect(() => {
		if (isChecked === true && windowWidth > mobileBreakpoint) {
			setIsChecked(false);
		}
	}, [windowWidth]);

    return (
		windowWidth > mobileBreakpoint
		?
			<header className={conc(styles.header, styles.header_desktop)}>
				<Logo/>

				<nav className={styles.nav_desktop}>
					<HashLink to="/#services" smooth={true} className={conc(styles.link, styles.link_desktop, styles.link_hover)}>Services</HashLink>
					<HashLink to="/#process" smooth={true} className={conc(styles.link, styles.link_desktop, styles.link_hover)}>Process</HashLink>
					<HashLink to="/#contact" smooth={true} className={conc(styles.link, styles.link_desktop, styles.contact_button)}>Contact Us</HashLink>
				</nav>
			</header>
		:
			<header className={conc(styles.header, styles.header_mobile)}>
				<Background isActive={isChecked} onClick={() => setIsChecked(false)}/>

				<Logo/>

				<div>
					<div className={styles.background} onClick={() => setIsChecked(!isChecked)}></div>

					<nav className={conc(styles.nav_mobile, cond(isChecked, styles.nav_mobile_visible))}>
						<HashLink to="/#services" smooth={true} className={conc(styles.link, styles.link_mobile, styles.link_hover)} onClick={() => setIsChecked(!isChecked)}>Services</HashLink>
						<HashLink to="/#process" smooth={true} className={conc(styles.link, styles.link_mobile, styles.link_hover)} onClick={() => setIsChecked(!isChecked)}>Process</HashLink>
						<HashLink to="/#contact" smooth={true} className={conc(styles.link, styles.link_mobile, styles.contact_button)} onClick={() => setIsChecked(!isChecked)}>Contact Us</HashLink>
					</nav>
				</div>

				<input type="checkbox" className={styles.menu_checkbox} id="menu_toggle" onChange={(event) => setIsChecked(event.currentTarget.checked)} checked={isChecked}/>
				<label className={styles.menu_button} htmlFor="menu_toggle">
					<div className={styles.menu_button_part}></div>
				</label>
			</header>
    );
}